import { generateLocale } from './generate.js';

export default generateLocale([
  'NickName',
  'NickName cannot be less than 3 bytes.',
  'E-Mail',
  'Please confirm your email address.',
  'Website',
  'Optional',
  'Comment here...',
  'No comment yet.',
  'Submit',
  'Like',
  'Cancel like',
  'Reply',
  'Cancel reply',
  'Comments',
  'Refresh',
  'Load More...',
  'Preview',
  'Emoji',
  'Upload Image',
  'seconds ago',
  'minutes ago',
  'hours ago',
  'days ago',
  'just now',
  'Uploading',
  'Login',
  'logout',
  'Admin',
  'Sticky',
  'Words',
  'Please input comments between $0 and $1 words!\n Current word number: $2',
  'Anonymous',
  'Dwarves',
  'Hobbits',
  'Ents',
  'Wizards',
  'Elves',
  'Maiar',
  'GIF',
  'Search GIF',
  'Profile',
  'Approved',
  'Waiting',
  'Spam',
  'Unsticky',
  'Oldest',
  'Latest',
  'Hottest',
  'What do you think?',
]);
