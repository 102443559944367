import { generateLocale } from './generate.js';

export default generateLocale([
  'Nombre de usuario',
  'El nombre de usuario no puede tener menos de 3 bytes.',
  'Correo electrónico',
  'Por favor confirma tu dirección de correo electrónico.',
  'Sitio web',
  'Opcional',
  'Comenta aquí...',
  'Sin comentarios todavía.',
  'Enviar',
  'Like',
  'Anular like',
  'Responder',
  'Anular respuesta',
  'Comentarios',
  'Recargar',
  'Cargar Más...',
  'Previsualizar',
  'Emoji',
  'Subir Imagen',
  'segundos atrás',
  'minutos atrás',
  'horas atrás',
  'días atrás',
  'justo ahora',
  'Subiendo',
  'Iniciar sesión',
  'cerrar sesión',
  'Admin',
  'Fijado',
  'Palabras',
  'Por favor escriba entre $0 y $1 palabras!\n El número actual de palabras: $2',
  'Anónimo',
  'Enanos',
  'Hobbits',
  'Ents',
  'Magos',
  'Elfos',
  'Maiar',
  'GIF',
  'Buscar GIF',
  'Perfil',
  'Aprobado',
  'Esperando',
  'Spam',
  'Desfijar',
  'Más antiguos',
  'Más recientes',
  'Más vistos',
  '¿Qué piensas?',
]);
