/* de.ts */

import { generateLocale } from './generate.js';

export default generateLocale([
  'Benutzername',
  'Der Benutzername darf nicht weniger als 3 Bytes umfassen.',
  'E-Mail',
  'Bitte bestätigen Sie Ihre E-Mail-Adresse.',
  'Webseite',
  'Optional',
  'Kommentieren Sie hier...',
  'Noch keine Kommentare.',
  'Senden',
  'Gefällt mir',
  'Gefällt mir nicht mehr',
  'Antworten',
  'Antwort abbrechen',
  'Kommentare',
  'Aktualisieren',
  'Mehr laden...',
  'Vorschau',
  'Emoji',
  'Ein Bild hochladen',
  'Vor einigen Sekunden',
  'Vor einigen Minuten',
  'Vor einigen Stunden',
  'Vor einigen Tagen',
  'Gerade eben',
  'Hochladen läuft',
  'Anmelden',
  'Abmelden',
  'Admin',
  'Angeheftet',
  'Wörter',
  'Bitte geben Sie Kommentare zwischen $0 und $1 Wörtern ein! Aktuelle Anzahl der Wörter: $2',
  'Anonym',
  'Zwerge',
  'Hobbits',
  'Ents',
  'Magier',
  'Elfen',
  'Maïar',
  'GIF',
  'Nach einem GIF suchen',
  'Profil',
  'Genehmigt',
  'Ausstehend',
  'Spam',
  'Lösen',
  'Älteste',
  'Neueste',
  'Am beliebtesten',
  'Was denken Sie?',
]);
