import { generateLocale } from './generate.js';

export default generateLocale([
  'Apelido',
  'Apelido não pode ser menor que 3 bytes.',
  'E-Mail',
  'Por favor, confirme seu endereço de e-mail.',
  'Website',
  'Opcional',
  'Comente aqui...',
  'Nenhum comentário, ainda.',
  'Enviar',
  'Like',
  'Cancel like',
  'Responder',
  'Cancelar resposta',
  'Comentários',
  'Refrescar',
  'Carregar Mais...',
  'Visualizar',
  'Emoji',
  'Enviar Imagem',
  'segundos atrás',
  'minutos atrás',
  'horas atrás',
  'dias atrás',
  'agora mesmo',
  'Enviando',
  'Entrar',
  'Sair',
  'Admin',
  'Sticky',
  'Palavras',
  'Favor enviar comentário com $0 a $1 palavras!\n Número de palavras atuais: $2',
  'Anônimo',
  'Dwarves',
  'Hobbits',
  'Ents',
  'Wizards',
  'Elves',
  'Maiar',
  'GIF',
  'Pesquisar GIF',
  'informação pessoal',
  'Aprovado',
  'Espera',
  'Spam',
  'Unsticky',
  'Mais velho',
  'Mais recentes',
  'Mais quente',
  'O que você acha?',
]);
